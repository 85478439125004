module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"careerskitchen","pages":[{"type":"Website_page","match":"/:uid","component":"/tmp/build_de53108b/src/templates/page.js"},{"type":"Blog_post","match":"/blog/:uid","component":"/tmp/build_de53108b/src/templates/blog-post.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170315663-2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
